import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import { Parallax } from "react-scroll-parallax"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCardHome from "../components/postCardhome"
import PostCardFeatured from "../components/postCardFeatured"

import "focus-visible"

// import "../utils/global.scss"
// import "../utils/normalize.css"
import "../utils/css/screen.css"

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const postsFeature = data.first.edges
  const allPosts = data.second.edges

  let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Compartilhada"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <div className="row">
        <div className="col-12" style={{ zIndex: "1" }}>
          <div className="post-feed">
            {postsFeature.map(({ node }) => {
              return (
                <PostCardFeatured
                  key={node.slug}
                  node={node}
                  postClass={`post`}
                />
              )
            })}
          </div>
        </div>
      </div>
      <a
        target="_blank"
        href="https://open.spotify.com/show/41mg1bZ5xsLMIkp180VwYI?si=qCakQwoURli3mskziSMIKg&app_destination=copy-link"
      >
        <div id="marquee2-wrap-white">
          <div id="marquee2">
            ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO
            PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O
            NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST!
            ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO
            PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O
            NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST!
            ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO
            PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O
            NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST!
            ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO
            PODCAST! ESCUTE O NOSSO PODCAST! ESCUTE O NOSSO PODCAST!
          </div>
        </div>
      </a>
      <ParallaxProvider>
        <div
          style={{
            backgroundImage:
              "radial-gradient(#E4FFCE 5%,#E4FFCE 15%, white 60%)",
            zIndex: "-100000",
            marginTop: "50px",
          }}
        >
          <div
            className="row"
            style={{ paddingTop: "10px", paddingBottom: "100px" }}
          >
            <div className="col-12">
              <div className="post-feed-home">
                {allPosts.map(({ node }) => {
                  return (
                    <PostCardHome
                      key={node.slug}
                      node={node}
                      postClass={`post`}
                    />
                  )
                })}
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center", paddingBottom: "100px" }}>
            <a className="view-button" style={{}} href={`/todos`}>
              Ver mais
            </a>
          </div>
        </div>

        <Parallax
          className="custom-class"
          y={[0, 0]}
          tagOuter="figure"
          style={{ backgroundColor: "#000" }}
        >
          <div
            className="post-content"
            style={{
              paddingTop: "50px",
              paddingBottom: "100px",
              textAlign: "center",
              backgroundColor: "#000",
              zIndex: "1",
            }}
          >
            <h1
              className="post-content-header"
              style={{ color: "#fff", fontSize: "3vw" }}
            >
              A toda a comunidade criativa de Belo Horizonte.
            </h1>
            <p
              className="blog-body post-content-home"
              style={{ color: "#fff", marginTop: "20px" }}
            >
              Estamos aqui para contar histórias não contadas antes,
              compartilhar rede, conhecimento e ser um espaço de inspiração para
              desenvolvimento de ideias. A partir do dia 18/10 você pode ser
              parte disso!
            </p>
          </div>
        </Parallax>
      </ParallaxProvider>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }

    first: allContentfulAllContentTypes(
      sort: { order: DESC, fields: publishedDate }
      limit: 1
      filter: { category: { ne: "Preview" } }
    ) {
      edges {
        node {
          title
          author
          category
          publishedDate(formatString: "DD/MM/YY")
          description
          createdAt
          slug
          image {
            fluid(maxWidth: 2048, quality: 100) {
              src
            }
          }
        }
      }
    }

    second: allContentfulAllContentTypes(
      sort: { order: DESC, fields: publishedDate }
      skip: 1
      limit: 9
      filter: { category: { ne: "Preview" } }
    ) {
      edges {
        node {
          title
          author
          category
          publishedDate(formatString: "DD/MM/YY")
          description
          createdAt
          slug
          image {
            fluid(maxWidth: 2048, quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
