import React from "react"
import { Link } from "gatsby"

export default props => (
  <article
    className={`post-card-featured ${props.count % 1 === 0 && `post-card-large-featured`} ${
      props.postClass
    } ${props.node.image ? `with-image` : `no-image`}`}
    style={
      props.node.image && {
        backgroundImage: `url(${
          props.node.image.fluid.src
        })`,backgroundSize: "cover"
      }
    
    }
  >
    
      <div className="post-card-content" >
        
        <h2 className="post-card-title" style={{marginTop: "10vw"}}>
          <a href={`/${props.node.slug}`} className="featuredLink">
            {props.node.title || props.node.slug}
          </a>
        </h2>
       
      </div>
    
  </article>
)
