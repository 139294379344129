import React from "react"

export default props => (
  <article className="article-home">
  
    <table>
      <tr>
        <td className="small-td" style={{verticalAlign: "top"}}>

          <div className="">
          <a href={`/${props.node.slug}`}>
            <div style={{
                backgroundImage: `url(${
                  props.node.image.fluid.src
                })`, height: "150px", width: "250px", display: "inline-block", backgroundSize: "cover"
              }}>
             
            </div>
             </a>
          </div>
        </td>
        <td className="big-td" style={{verticalAlign: "top"}}>

          <div className="title-card-home" style={{verticalAlign: "top", textAlign: "center"}}>
            <a href={`/${props.node.slug}`}>
              <h2 className="card-home-text" style={{verticalAlign: "top"}}>
                {props.node.title || props.node.slug}
              </h2>
            </a>
          </div>
        </td>
    
        <td className="small-td" style={{verticalAlign: "top"}}>
          <a href={`/${props.node.slug}`}>
          <div className="description-card-home" style={{ verticalAlign: "top"}}>
            <div style={{verticalAlign: "top"}}>
              {props.node.description}
            </div>
            
          </div>
          </a>
        </td>
      </tr>
       <tr>
        <td className="small-td" style={{verticalAlign: "top"}}>
        <a href={`/${props.node.slug}`}>
        <div style={{textAlign: "left", color: "#79FE0C", verticalAlign: "baseline", fontSize: "1.5em", fontFamily: "Krona one"}}>{props.node.publishedDate}</div>
        </a>
        </td>
        <td className="big-td" style={{verticalAlign: "top"}}>
        <a href={`/${props.node.slug}`}>
        <div style={{textAlign: "center", color: "#79FE0C", verticalAlign: "baseline", fontSize: "1.5em", fontFamily: "Krona one"}}>{props.node.author}</div>
        </a>
        </td>
        <td className="small-td" style={{verticalAlign: "top"}}>
        <a href={`/${props.node.slug}`}>
          <div style={{textAlign: "right", color: "#79FE0C", fontSize: "1.5em", fontFamily: "Krona one"}}>
            {props.node.category}
          </div>
          </a>
        </td>
      </tr>
    </table>

  </article>
)
